<template>
    <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    metaInfo() {
        return Object.assign({}, this.$data.seo);
    },
    data: () => ({
        seo: {},
        timer: null,
        latest: Date.now(),
    }),
    computed: {
        ...mapState(["likes"]),
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(atob(this.refreshToken?.split(".")[1] || "") || "{}");
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.getSetting__v1();
        this.getIslands();
        this.getShipping__everyAsset();
        this.getCategories();
        if (!this.likes) this.getLikes();
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            } else {
                if (!this.likes) this.getLikes();
            }
        },
    },
    methods: {
        ...mapActions(["getSetting__v1", "getLikes", "getIslands", "getShipping__everyAsset", "getCategories"]),

        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            clearTimeout();
            this.timer = setTimeout(() => {
                const inactiveLimit = 24 * 60 * 60 * 1000; // 비활성화 24시간 뒤 로그아웃
                const inactiveLimitPassed = inactiveLimit < Date.now() - this.latest;
                const refreshTokenExpired = this.payload.exp * 1000 <= Date.now();
                if (inactiveLimitPassed || refreshTokenExpired) {
                    this.$store.dispatch("logout");
                }
                this.onTimer();
            }, 1000);
        },
    },
};
</script>
